@import "uikit";



$green: greenyellow;

:root {
  --background: rgb(255, 255, 255);
  --background-opacity: rgba(255, 255, 255, 0.75);
  --text-primary: black;
  --text-primary-opacity: rgba(0,0,0,0.75);
  --text-secondary: $green;
  --accent: purple;
}
[data-theme="dark"] {
  --background: black;
  --background-opacity: rgba(0, 0, 0, 0.75);
  --text-primary: white;
  --text-primary-opacity: rgba(255, 255, 255, 0.75);

  --text-secondary: $green;
  --accent: darkred;

  .logo {
    filter: invert(100%);
  }

  path {
    stroke:  var(--text-primary);
  }
}

$background: var(--background);

::-moz-selection {
  /* Code for Firefox */
  color: red;
  background: pink;
}

::selection {
  color: black;
  background: $green;
}

.mouse {
  position: relative;
}

.everything {
  color: var(--text-primary);
  background-color: var(--background);

  min-height: 100vh;
  transition: all 0.5s;
}

body,
html {
 // font-family: Canela;
  background-color: var(--background);
  cursor: crosshair;

}

a{
 cursor: url(../assets/images/cursor.png), auto;	
}

.darkmode {
  color: var(--text-primary);

  border: none;
  transition: all 0.5s;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 10;
}

// UIKIT OVERRIDES
.uk-navbar-container:not(.uk-navbar-transparent) {
  background: none;
}

a{
  color: var(--text-primary);
}

.uk-navbar-nav > li > a {
  color: var(--text-primary) !important;
}

.uk-offcanvas-bar{
  background-color: $green;

  li a{
    color:black !important;
    font-size: 3rem;
  }
}

.close-buttom-menu {
  margin-top:2rem;

  a{
    color:black !important;
  
  }
}

//Home Page

.homepage {
  position: relative;

  
}
.homepage-spacer {
  height: calc(33vh - 100px);
  width:100%;
  position:relative;
  float:left;
pointer-events: none;


}

#exp {
  fill: var(--text-primary);
  max-width: 100%;
}


.opener {
 // background-color: red;
 
  position: absolute;
  height:33vh;
  width: 100%;
  top:-100px;
  overflow: hidden;

  video {
    position: absolute;
    top:0;
    left:0;
    
  }
  

}

.skill-background {
  width: 100vw;
 position: absolute; 
  z-index: 0;

top:-25%;

  height: 100vh;
  left: -8vw;
 overflow: hidden;
  z-index: 0 !important;
  transition: 2s;
display:none;
pointer-events: none;
  
}



.skill-image {
 
}


.st0{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

.st5{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: rgb(255, 0, 0) !important;
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

.st6{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: rgb(0, 47, 255) !important;
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

.st7{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: rgb(255, 174, 0) !important;
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

.st8{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: var(--text-primary);
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

.st9{
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: dashfront 5s linear;
  animation-iteration-count: 1;

  fill: none;
  stroke: rgb(73, 182, 22) !important;
  stroke-linecap: round;
  stroke-width: 1;
  opacity: 1;
  z-index: 0 !important;
  pointer-events: none;
}

@keyframes dashfront {
  0% {
    stroke-dashoffset: 1;
  }
  100% {
    stroke-dashoffset: 0;
   
  }
}


.illustrationsvg{
  fill:none;stroke:#231F20;stroke-width:1.3264;stroke-miterlimit:10;

}




.skill:hover div {
  font-style: italic;
  opacity: 1 !important;
  display:inline;
  transition: 1s;
 

}

.skill-container:hover .skill .skill-title{
opacity: 0.25;
transition: 0.5s;
}

.skill-container:hover .skill .skill-image{
  opacity: 0.1;
  }


  .skill-container:hover h2{
    opacity: 0.25;
    }
  
.skill .skill-title{
  opacity: 14;
  }




.skill-container {
  font-family: Helvetica, sans-serif;
  font-size: 5rem;
  line-height: 1.1;
  text-align: center;
  position: relative;
  width: 100%;

  .uk-grid:hover {
    background: plum;
  }

  .skill {
    margin: 0 1rem;
    //float:left;

    .skill-title:first-child {
    }
  }

  .skill:first-child {
   
  }

  span.small {
    font-size: 0.40em;
}

  .skill-spacer {
   
  }

  .skill-title {
   
  }
}

.digital-design {
}

//Work Page

.work-container {
  top: 0;
  color: var(--text-primary);
  background-color: var(--background);
  z-index: 10;

  .hero,
  h1,
  p {
    position: relative;
   
  }

  p {
    
  //  font-family: sans-serif;
  }
}

a:hover {
  text-decoration: 0;
  color: var(--text-secondary);
}

.image-container {
  div {
    padding: 0.5rem 0;
  }
}

.work-selected {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  transition: 1s;
}





.scribble1 {
  position: absolute;
  width: 100%;
  z-index: 1;

  pointer-events: none;

  z-index: 1;
}

.scribble2 {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0%;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
  pointer-events: none;
}

.path2 {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  fill: none;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 5;
  animation: dashBackwards 3s linear;
  width: 100vw;
  opacity: 0;
}

.scribble3 {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  width: 100vw;
}

.path3 {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  fill: none;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 5;
  animation-iteration-count: 1;

  animation: dashBackwards 3s linear;
  opacity: 0;
}

.path {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  fill: none;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 5;
  opacity: 0;
}

.uk-grid-margin{
padding-top:40px;
margin-top:0 !important;
}

.listItem:hover {
  opacity: 1;
  
  transition:0.5s;

img{
  transform: rotate(2.5deg);
}

  .path {
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: dash 750ms linear;
    animation-iteration-count: 1;

    fill: none;
    stroke: black;
    stroke-linecap: round;
    stroke-width: 5;
    opacity: 1;
  }
}

.listItem:nth-child(3n):hover {
  opacity: 1;
  
  transition:1s;
}

.listItem {
  position: relative;
  float:left;
  padding:1rem;
}

.uk-container {
 
 // overflow: hidden;
  overflow-y: visible;
}

.listItem:nth-child(odd) {
  .path {
    stroke: $green;
  }

  .scribble1 {
    transform: rotate(-90deg);
    overflow: hidden;
  }
}

.listItem:nth-child(3n) {
  .path {
    stroke: var(--text-primary);
  }

  .scribble1 {
    transform: rotate(66deg);
  }
}

.listItem:nth-child(3n) {
  .path {
    stroke: rgb(255, 0, 0);
  }

  .scribble1 {
    transform: rotate(310deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1;
  }
  100% {
    stroke-dashoffset: 0;
    display: none;
  }
}

@keyframes dashBackwards {
  0% {
    stroke-dashoffset: 1;
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 0;
  }

  70% {
    stroke-dashoffset: 0;
  }
  99% {
    stroke-dashoffset: 1;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 1;
    opacity: 0;
  }
}

@keyframes dashBackwards1 {
  0% {
    stroke-dashoffset: 1;
    opacity: 1;
  }
  50% {
    stroke-dashoffset: 0;
  }

  70% {
    stroke-dashoffset: 0;
  }
  99% {
    stroke-dashoffset: 1;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 1;
    opacity: 0;
  }
}

h1,
h2,
h3 {
  color: var(--text-primary);
 // font-family: Canela;
}

.listItem {
  h3 {
    margin: 0;
  }
}

//LOADER

.loader {
  pointer-events: none;
  background: var(--background);
  position: fixed;
  top: 0;
  background-position: center;
  background-size: 25vw;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  animation: dashBackwards1 3s linear;
  opacity: 0;
  z-index: 100;
  transform: none !important;
  transition:0.333s;
}

.loader-before {
  text-align: center;

  padding: 1rem;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  font-size: 25vh;
  line-height: 1;
  overflow: hidden;
  padding: 0;
  z-index: 2;
  pointer-events: none;
  line-height: 0.5;
  background: var(--background-opacity);
}



.hide {
  opacity: 0;
}

.listItem:hover + .hide {
  // transition: 0.666s;
  opacity: 1;
  margin: 0;
  transform: none !important;
}

.header-work-loading {
  z-index: 31;
  color: var(--text-primary);
}

.listItem:hover{
  h3{
    opacity: 0.25;
  }
}

.header-work-loading {
  text-align: center;
  background: var(--text-primary);
  margin-top:10vh;
  position: static;
  width: 100vw;
 top:0;
  left: 0;
  background-position: center;
  background-size: contain;
  image-rendering: pixelated;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15vw;
  line-height: 1;
  overflow: hidden;

  pointer-events: none;
}

.header-work {
  text-align: center;
  margin-top:10vh;
 
//font-family: Knockout;
  width: 100vw;
 position: absolute;
  left: 0;
  background-position: center;
  background-size: contain;
  padding: 0;
  font-size: 15vw;
  line-height: 1;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.header-work-loading-start {
  text-align: center;
  margin-top:10vh;
 
//font-family: Knockout;
  width: 100vw;
 
  left: 0;
  background-position: center;
  background-size: contain;
  padding: 0;
  font-size: 15vw;
  line-height: 1;
  overflow: hidden;
  z-index: 3100;
  pointer-events: none;
 
}

.work-tags{
  font-size: 4rem;
    position: fixed;
    bottom: 10%;
    text-align: center;
    width: 100%;
    z-index: 40;
    font-family: Helvetica, sans-serif;
    margin:0;
   
}

.work-type{
  font-size: 1.5rem;
  color: $green;
  font-family: 'Courier New', Courier, monospace;
  margin:0
}

.header-work-loading.work-tags:nth-child(2){left:0}


.listItem:hover {
  opacity: 1 !important;
  z-index: 10;
  position: relative;

  .loader-before {
    opacity: 1;
  }
}



.hoverable:not(:hover) + .show-on-hover {
  display: none;
}

@keyframes dashBackwards1 {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

//SingleWork



//About

.about-text{
  font-size: 1.75rem;
  line-height: 1.2;
  letter-spacing: -0.06rem;
  margin-bottom: 40px;
  text-indent: 3rem;
  font-family: Helvetica, sans-serif
}

// ARTWORK

.art-container{

  padding:2rem 2rem 0rem 2rem;

 
}
.artbox #mouse-parallax-container{
  overflow: visible !important;
  padding-bottom:4rem;
  margin-top: -10rem;
}

.artbox #mouse-parallax-container div:first-child{
  opacity: 1 !important;
}

#mouse-parallax-container{
  overflow: visible !important;

}

.grid1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  column-gap: 0.5rem;
}

.grid1 div:nth-child(1n) {
  margin-top:7rem;
}

.grid1 div:nth-child(3n) {
  margin-top:2rem;

}
.grid1 div:nth-child(2n) {
  margin-top:0.2rem;

}

.grid1 div:nth-child(2n) img {
  margin-left:3rem;
  visibility: visible !important;
}


.order-art{
  z-index: 25;
  position: absolute;
 bottom:0;
}

.art-scroll {
 // width: 100vw !important;
  //height: 100vh !important;
  height: 100vh;
 
    position: absolute;
    top: 0;
    overflow: hidden;
    right:0;
  padding:0 5rem;
   
}

.test-art{
 
 
}

.art-items-container div{
 // width: 100vw !important;
  //height: 10vh !important;
}

.react-transform-wrapper{
//  width: 100vw !important;

  height: 100vh !important;
 overflow: visible !important;
 
}

.react-transform-component{
  padding-right: 2rem !important;
  padding-bottom:5rem !important;
  padding-top:10rem !important;
}

.transition{
  transition: 5s;

}

//TYPESCALES

/* Perfect Fourth - 1.333 Medium size screens */
@media only screen and (min-width: 700px) {
  h1,
  h2,
  h3,
  h4 {
    margin: 1.414em 0 0.5em;

    line-height: 1;
  }
  h1 {
    margin-top: 1.5rem;
    font-size: 3.157em;
  }
  h2 {
    font-size: 2.369em;
  }
  h3 {
    font-size: 1.777em;
  }
  h4 {
    font-size: 1.333em;
  }
  small,
  .font_small {
    font-size: 0.75em;
  }
}

/* Perfect fifth 1.5 Desktop and large devices */
@media only screen and (min-width: 900px) {
  h1,
  h2,
  h3,
  h4 {
    margin: 1.414em 0 0.5em;

    line-height: 1.2;
  }
  h1 {
    margin-top: 0;
    font-size: 5.063em;
  }
  h2 {
    font-size: 3.375em;
  }
  h3 {
    font-size: 2.25em;
  }
  h4 {
    font-size: 1.5em;
  }
  small,
  .font_small {
    font-size: 0.667em;
  }
}

/* Golden Ratio 1.618 - Large screens */
@media only screen and (min-width: 1200px) {
  h1,
  h2,
  h3,
  h4 {
    margin: 1.414em 0 0.5em;

    line-height: 1.2;
  }
  h1 {
    margin-top: 0;
    font-size: 6.854em;
  }
  h2 {
    font-size: 4.236em;
  }
  h3 {
    font-size: 2.618em;
  }
  h4 {
    font-size: 1.618em;
  }
  small,
  .font_small {
    font-size: 0.618em;
  }

  .filmpage-title {
    h1 {
      font-size: 11em;
    }
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }


  
}


//FOOTER


.country {
  font-family: "Courier New", Courier, monospace;
  margin-top: 5em;
}

.footer {
  padding-top: 1.5rem;

  font-family: "Courier New", Courier, monospace;

  .windings {
    font-family: "Wingdings";
    text-align: center;
  }

.footer-contact{

svg{
  vertical-align: inherit;
  fill: var(--text-primary);
  width: 2.5vw;
}

}

  h4,
  h5 {
  }
}

.say-hi {
  text-transform: uppercase;
    font-size: 6vw;
    line-height: 0.88;
    text-align: justify;
}

.above-footer {
  height: 1.5rem;

  width: 100vw;

  margin-top: 4em;
}

.footer-contact {
  font-family: Helvetica, sans-serif;
  font-size: 4vw;
  line-height: 0.88;
  text-align: justify;
}



// LIST





.menu {
	padding: 5rem 0 40vh 0;
	position: relative;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	text-align: right;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	counter-reset: menucounter;
}

.menu__item {
	flex: none;
	display: flex;
	justify-content: flex-end;
	position: relative;
	padding: 0 15vw;
}

.open-lightbox{
  position: fixed;
  right: 0;
 
  z-index: 1;
  bottom: 0;
  color: var(--text-primary);
 
  width: 100%;
  text-align: center;
  font-size: 4rem;
}

.hidden{
  display:none;
}

.menu__item::before {
	counter-increment: menucounter;
	content: counters(menucounter, ".", decimal-leading-zero);
	position: absolute;
	left: calc(15vw - 2rem);
	color: var(--color-menu-deco);
	top: 0%;
	height: 60%;
	display: flex;
	align-items: center;
	line-height: 1;
	font-weight: bold;
	opacity: 0;
	transform: translateX(-1rem);
	transition: transform 0.3s, opacity 0.3s;
}

.menu__item:hover::before {
	color: var(--color-menu-deco);
	opacity: 1;
	transform: translateX(0);
}

.bg{
  opacity: 1;
  width:100px;
  height:100px;
  z-index: 0;
 
}

.menu__item:hover {
  
  .bg::first-child{
    opacity: 1;
  }

}

.menu__item-text {
	position: relative;
	cursor: pointer;
	display: block;
	line-height: 1;
	font-size: clamp(2rem, 6vw, 6rem);
}

.menu__item-textinner {
	display: block;
	font-family: sans-serif;
	font-weight: 400;
	text-transform: uppercase;
	white-space: nowrap;
  color: var(--color-menu);
  position: relative;
  z-index: 1;
}


.list-image{

  transition: transform 0.3s;
  width: 75%;
  position: fixed;
  left: 50%;
  margin-left: -37.5%;
  top: 20%;
  transition: transform 0.3s;
  
}

.list-image img{
  pointer-events: none;
  max-height:85vh;
}

.menu__item a {
  z-index: 50;
  color: var(--color-menu);
 font-style: italic;
 font-family: 'Courier New', Courier, monospace;
  width:100%;
  font-size:1rem;
  text-align: right;
  float:right;
}

.menu__item:hover::first-child{
  opacity: 1;
}

.menu__item-sub::before {
	content: '';
	position: absolute;
	left: 0;
	top: 15%;
	width: 1px;
	height: 70%;
	background: currentColor;
	transform-origin: 0 100%;
	transform: rotate(22.5deg) scale3d(1,0,1);
	transition: transform 0.3s;
}

.menu__item:hover .menu__item-sub::before {
	transform: rotate(22.5deg) scale3d(1,1,1);
}

.hover-reveal {
	position: absolute;
	z-index: -1;
	width: 200px;
	height: 280px;
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	will-change: transform, filter;
}

.hover-reveal__inner {
	overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
	width: 100%;
	height: 100%;
	position: relative;
}

.hover-reveal__img {
	background-size: cover;
	background-position: 50% 50%;
}

.cursor {
	display: none;
}

.credits {
	align-self: flex-start;
	padding: 5rem 0 0 0;
}

.credits a {
	text-decoration: underline;
}



  //MOBILE
  @media only screen and (max-width: 700px) {
    html {
      font-size: 100%;
    }

    .bg {
      opacity: 1;
      width: 50px;
      height: 50px;
      z-index: 0;
  }

  .uk-grid {
    margin-left: 0;
  }


  .menu__item {
    padding: 0 2.5vw 0 0;
  }

  .homepage-spacer{
    //display:none;
    height: calc(45vh - 100px);
  }

  .opener {
    height: 45vh;

    video {
      min-height: 45vh;
      width: auto !important;
      height: 45vh !important;
      max-width: 1000% !important;

    }
  }


    .grid1 {
      display: grid;
      grid-template-columns: 1fr ;
      grid-template-rows: auto auto auto auto;
      column-gap: 0.5rem;
    }

    .skill{
  
      float: left;
      width: 100%;
      padding: 1rem 0;
      text-align: center;
      margin: 0 !important;

    }

    .skill-spacer{
      display:none !important;
    }

    .skill-title{
      font-size:2rem;
      padding:0;
      float:right;
    }

    .skill-container{
    
    position: relative;
    }

    .skill-background{
      width: 160vh;
      position: absolute;
      margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    
    }

    svg{
    
    }

    .listItem {
      overflow: hidden;
    }

    .skill-image{width:3rem; height:2rem; float: right !important;}

    .grid1 div:nth-child(1n) {
      margin-top:1;
    }
    
    .grid1 div:nth-child(3n) {
      margin-top:1;
    
    }
    .grid1 div:nth-child(2n) {
      margin-top:1;
    
    }
    
    .grid1 div:nth-child(2n) img {
      margin-left:0rem;
      visibility: visible !important;
    }

  h1{
    margin-top:2rem;
  }

  .worklist .work-images {
  width: 100%;
  max-height: 100%;

  object-fit: contain;
  
  }

  .uk-slider-items{

    align-items:flex-start;
  }

    video{
      max-width: 100%;
     // padding-right: 15px;
    }

    .uk-grid > * {
  padding:0;
}

    .work-item p{
      font-size: 14px;
    }
  }



.worklist{
  border-bottom:1.5px solid (var(--text-primary));
  padding:1rem 0;
overflow: hidden;

  .uk-slider-container{
    overflow: visible;
    cursor: grab;
  }

  h5{
    margin:0.5em 0;
    font-weight: bold;
    color: (var(--text-primary))
  }
  p{
    margin-top:0;
  }
  video{
    max-width: auto;
    max-height: 40vh;
    width: auto;
  }
  .work-images {
    max-width: auto;
    max-height: 40vh;
    width: auto;

    img{
      max-height: 100%;
    }
  }

   .work-images{
    padding-right: 15px;
  }


  .uk-accordion-title{
    font-size: 18px;
    margin-top: 1rem;
    color: (var(--text-primary))
  }

}

.uk-navbar-sticky{
  transition: 0.5s;

  p{
    font-size: 10px;
  }
}

.header{


  h5, p{
    margin:0;
    color:  var(--text-primary);
  }
  p{
    font-size: 14px;
    transition: 0.5s;
  }

  h5{
    margin-top:1rem;
  }
}
h5{font-weight: bold;}

.uk-sticky{
  border-bottom: 1px solid var(--text-primary-opacity);
  transition: 0.5s;
  padding-bottom: 0.75rem;

}

.uk-navbar-sticky{
  transition: 0.5s;
  background-color: (var(--background-opacity));

  p{
    font-size: 10px;
    transition: 0.5s;
  }
}


.ui-button{
  font-size: 2rem;
}

.inner-nav {
  color: black;

  h5{
    margin:0;
    color: black;
  }

  a{
    color: black;
    font-weight: bold;
  }

  p {
    margin-top: 0;
  }
}

.four04{
  position: absolute;
  height: 100vh;
  top: 0;
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.four04 + div{
display: none;
}